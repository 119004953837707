<template>
    <div class="inner-view">
        <div class="application-overview-header">
            <h3>{{ $t('Applications') }}</h3>
            <span class="rounded-badge">{{ vacancies.length }}</span>
        </div>
        <div class="row">
            <div class="col-6" v-for="vacancy in vacancies" :key="vacancy.id">
                <VacancyItem :vacancy="vacancy" @getApplications="loadApplication(vacancy.id)" />
            </div>
        </div>
    </div>
</template>

<script>
import VacancyItem from "@/components/applications/VacancyItemComponent";
import { applicationService } from '@/services/applicationService'

export default {
    components: {
        VacancyItem
    },
    methods: {
        getApplications() {
            applicationService.summary().then(response => {
                this.$store.commit('setApplicationsVacancies', response.data.data)
            })
        },
        loadApplication(id) {
            this.$emit('getApplication', id);
        }
    },
    created() {
        this.getApplications();
    },
    computed: {
        vacancies() {
            console.log(this.$store.state.applications.vacancies);
            return this.$store.state.applications.vacancies
        }
    },
}
</script>